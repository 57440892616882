
  import CategoriesMenuItems from './../navbar/CategoriesMenuItems';
  import BDropdownHover from './../BDropdownHover';

  export default {
    components: {
      CategoriesMenuItems,
      BDropdownHover,
    },
    props: {
      megamenu: {
        type: Object,
        default: () => [],
        required: false,
      },
    },
    methods: {
      validHtml(html) {
        try {
          const div = document.createElement('div');
          div.innerHTML = html;
          return div.innerHTML;
        } catch (e) {
          return html; //'Invalid html';
        }
      },
    },
  };
